<template>
  <div class="constrain">
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <h4 class="mb-8" v-html="payload.titel" />
        <div class="subtitle text-navy mb-16" v-html="payload.untertitel" />
      </div>
    </div>
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <div v-for="requirement in requirements" :key="requirement.id" class="row-12 requirement-box">
          <div class="md:col-4">
            <p class="text-navy font-bold" v-html="requirement.titel" />
          </div>
          <div class="md:col-7 md:offset-1 text-navy" v-html="requirement.beschreibung" />
        </div>
      </div>
    </div>
    <div class="row-12 mt-32">
      <div class="md:col-8 md:offset-2">
        <h4 class="mb-8" v-html="payload.dauer" />
        <div class="subtitle text-navy mb-16" v-html="payload.dauertext" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    requirements() {
      return this.payload.einzelpunkte;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>

.requirement-box:not(:last-child) {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid var(--color-light-blue);
}
</style>
